import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { setupQueryConfig } from '../../config/query.config';
import { getRoute } from '../../config/routes.config';
import {
  TeamForm,
  TeamFormBody,
  teamInitialValues,
  teamValidationSchema,
} from '../../forms/team.form';
import {
  useDeleteUserMutation,
  useTeamEditSceneQuery,
  useUpdateTeamMutation,
} from '../../generated/graphql';

export const TeamEditScene = () => {
  const { t } = useTranslation('TeamEditScene');
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) throw new Error('Missing ID');
  const query = useTeamEditSceneQuery(setupQueryConfig({ id }));

  const user = query.data?.user;

  const [deleteUser, { loading: isDeleteLoading }] = useDeleteUserMutation({
    variables: { id },
    onCompleted: (data) => {
      if (data?.deleteUser) {
        toast.success(t('Common:deleted'));
        navigate(getRoute('TEAM_INDEX'));
      }
    },
  });

  const [updateUser, { loading }] = useUpdateTeamMutation({
    onCompleted: (data) => {
      if (data.updateTeam) {
        toast.success(t('Common:updated'));
        navigate(getRoute('TEAM_INDEX'));
      }
    },
  });

  const handleUpdate = useCallback(
    (body: TeamFormBody) => {
      let formBody = {
        ..._.cloneDeep(body),
        groups: body.groups.map((g) => g.value),
        defaultGroups: body.defaultGroups.map((g) => g.value),
      };
      formBody = _.omit(formBody, '__typename', 'isCompanyOwner');
      updateUser({ variables: { id: id, body: formBody } });
    },
    [updateUser, id]
  );

  if (query.loading || !query.data) return <Loading />;

  return (
    <FormLayout>
      <Card>
        <CardTitle>{t('title')}</CardTitle>
        <Formik
          validationSchema={teamValidationSchema}
          onSubmit={handleUpdate}
          initialValues={{
            ...teamInitialValues,
            ...query.data.user,
            groups: query.data.user.groups.map((g) => ({ value: g.id, label: g.name })),
            defaultGroups: query.data.user.defaultGroups.map((g) => ({
              value: g.id,
              label: g.name,
            })),
          }}
        >
          <Form>
            <TeamForm
              acls={query.data.acls.list}
              groups={query.data.groups.list}
              approvers={query.data.allDocumentApprovers}
            />
            <div className="mb-4">
              <Button type="submit" className="float-right" loading={loading || isDeleteLoading}>
                {t('Common:save')}
              </Button>
              <ButtonLink to={getRoute('TEAM_INDEX')}>{t('Common:back')}</ButtonLink>
            </div>
            {user?.isCompanyOwner ? null : (
              <>
                <hr />
                <Button
                  className="mt-4"
                  type="button"
                  onClick={() => {
                    if (confirm(t('areYouSure'))) deleteUser();
                  }}
                  loading={loading || isDeleteLoading}
                >
                  {t('Common:delete')}
                </Button>
              </>
            )}
          </Form>
        </Formik>
      </Card>
    </FormLayout>
  );
};
