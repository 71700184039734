import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import ReFetchContext from '../../contexes/ReFetchContext';
import UserContext from '../../contexes/UserContext';
import { userForm } from '../../forms/user.form';
import { useUpdateUserMutation, useUserEditWhoAmIQuery } from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { useSubmitHandler } from '../../releox-engine/use-submit-handler/use-submit-handler';

export const UserEditScene = () => {
  const { t } = useTranslation('UserEditScene');
  const [user] = useContext(UserContext);
  const refetch = useContext(ReFetchContext);

  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: useOnComplete(null, 'saved', () => refetch()),
  });

  const handleUpdate = useSubmitHandler(updateUser, { id: user.id });

  const query = useUserEditWhoAmIQuery();

  return (
    <AppFormScene
      type="edit"
      form={userForm}
      onSubmit={handleUpdate}
      query={query}
      title={t('editInformation')}
      queryDataPath="whoAmI"
      isSubmitLoading={loading}
      backLink={getRoute('DASHBOARD')}
    />
  );
};
