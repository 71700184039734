import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../components/Button';
import { reportInput } from '../inputs/report.input';
import { TextInput } from '../releox-engine/react-components/form-inputs/TextInput';

export const reportInitialValues = {
  start: reportInput.start.initValue,
  end: reportInput.end.initValue,
};

const validationSchema = Yup.object().shape({
  start: reportInput.start.validation,
  end: reportInput.end.validation,
});

export type ReportFormBody = typeof reportInitialValues;

type ReportFormProps = {
  onSubmit(body: ReportFormBody): void;
};

export const ReportForm = (props: ReportFormProps) => {
  const { t } = useTranslation('Common');
  return (
    <Formik
      initialValues={reportInitialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="md:flex md:space-x-10 space-y-4 md:space-y-0">
          <div className="md:w-48">
            <TextInput {...reportInput.start.props} />
          </div>
          <div className="md:w-48">
            <TextInput {...reportInput.end.props} />
          </div>
          <div className="md:float-left md:pt-6">
            <Button type="submit">{t('submit')}</Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
