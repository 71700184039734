import { useTranslation } from 'react-i18next';
import { reportInitialValues } from '../forms/report.form';
import { useHomeSceneReportQuery } from '../generated/graphql';
import { Card } from './Card';
import { CardTitle } from './CardTitle';
import { ReportQncCostChart } from './report/ReportQncCostChart';
import { ReportQncCountChart } from './report/ReportQncCountChart';
import { ReportQncTypeChart } from './report/ReportQncTypeChart';

export const HomeSceneReport = () => {
  const { t } = useTranslation('HomeScene');

  const { data, loading } = useHomeSceneReportQuery({
    variables: {
      start: reportInitialValues.start,
      end: reportInitialValues.end,
    },
  });

  return (
    <div className="xl:col-span-3 space-y-5">
      <Card>
        <div className="mb-2">
          <CardTitle>{t('ReportQncCountScene:title')}</CardTitle>
          <ReportQncCountChart data={data?.qncCountReport} loading={loading} hideCount />
        </div>
        <div className="mb-2 hidden sm:block">
          <CardTitle>{t('ReportQncTypeScene:title')}</CardTitle>
          <ReportQncTypeChart data={data?.qncCategoryReport} loading={loading} hideCount />
        </div>
        <div className="mb-2">
          <CardTitle>{t('ReportQncCostScene:title')}</CardTitle>
          <ReportQncCostChart data={data?.qncCostsReport} loading={loading} hideCount />
        </div>
      </Card>
    </div>
  );
};
