import { companyInput } from '../inputs/company.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [companyInput.name];

export const companyForm = createForm(form);

export type CompanyFormBody = {
  name: string;
};
