import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';
import {
  DocumentModelEditFormBody,
  documentModelEditForm,
} from '../../forms/document-model-edit.form';
import {
  useDeleteDocumentModelMutation,
  useDocumentModelEditSceneQuery,
  useUpdateDocumentModelMutation,
} from '../../generated/graphql';
import { useDocumentTitle } from '../../hooks/use-document-title';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const DocumentModelEditScene = (): JSX.Element => {
  const { t } = useTranslation('DocumentModelEditScene');
  const { id } = useParams();
  const navigate = useNavigate();
  if (!id) throw new Error('Missing ID');

  const [updateDocumentModel, { loading }] = useUpdateDocumentModelMutation({
    onCompleted: (data) => {
      if (data.updateDocumentModel) {
        toast.success(t('Common:updated'));
        navigate(getRoute('DOCUMENT_MODEL_INDEX'));
      }
    },
  });

  const [deleteDocumentModel, { loading: isDeleteLoading }] = useDeleteDocumentModelMutation({
    variables: { id },
    onCompleted: (data) => {
      if (data.deleteDocumentModel) {
        toast.success(t('Common:deleted'));
        navigate(getRoute('DOCUMENT_MODEL_INDEX'));
      }
    },
  });

  // Method to handle deleteDocumentModel call with prompt
  const handleDelete = useCallback(() => {
    if (window.confirm(t('confirmDelete'))) {
      deleteDocumentModel();
    }
  }, [deleteDocumentModel, t]);

  const query = useDocumentModelEditSceneQuery({ variables: { id } });

  const handleSubmit = useCallback(
    (body: DocumentModelEditFormBody) => {
      let data = _.cloneDeep(body);
      data = _.omit(data, 'type');
      data.documentModelFields = data.documentModelFields.map((field) => {
        return _.omit(field, '__typename');
      });
      updateDocumentModel({ variables: { id, body: data } });
    },
    [updateDocumentModel, id]
  );

  let deleteCaller: undefined | typeof handleDelete = undefined;

  if (
    query.data?.documentModelById.type &&
    !['qnc', 'risk', 'document', 'anonymous'].includes(query.data?.documentModelById.type)
  ) {
    deleteCaller = handleDelete;
  }

  const title = useDocumentTitle({ type: query.data?.documentModelById.type, t });

  return (
    <AppFormScene
      type="edit"
      query={query}
      queryDataPath="documentModelById"
      title={title}
      form={documentModelEditForm}
      isSubmitLoading={loading || isDeleteLoading}
      onDelete={deleteCaller}
      onSubmit={handleSubmit}
      backLink={getRoute('DOCUMENT_MODEL_INDEX')}
    />
  );
};
