import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { companyForm, CompanyFormBody } from '../../forms/company.form';
import { useCompanyEditSceneQuery, useUpdateCompanyMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const CompanyEditScene = (): JSX.Element => {
  const { t } = useTranslation('CompanyEditScene');
  const [user] = useContext(UserContext);

  const [updateCompany, { loading }] = useUpdateCompanyMutation({
    onCompleted: (data) => {
      if (data.updateCompany) {
        toast.success(t('Common:updated'));
      }
    },
  });

  const query = useCompanyEditSceneQuery({ variables: { id: user.company.id } });

  const handleUpdate = useCallback(
    (body: CompanyFormBody) => {
      updateCompany({ variables: { id: user.company.id, body } });
    },
    [updateCompany, user.company.id]
  );
  return (
    <AppFormScene
      type="edit"
      form={companyForm}
      onSubmit={handleUpdate}
      query={query}
      title={t('editInformation')}
      queryDataPath="company"
      isSubmitLoading={loading}
      backLink={getRoute('DASHBOARD')}
    />
  );
};
