import { userInput } from "../inputs/user.input";
import { createForm } from "../releox-engine/form/FormGenerator";
import { FormInput } from "../releox-engine/form/FormInput";

const form: FormInput[] = [userInput.email, userInput.password];

export const loginForm = createForm(form);

export type LoginFormBody = {
  email: string;
  password: string;
};
