import { Form, Formik } from 'formik';
import { get } from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { InputRenderer } from '../../../components/form-tools/InputRenderer';
import { useFilterInput } from '../../../inputs/filter.input';

const fields = ['name', 'email', 'resourceACLId', 'isApprover', 'groupId'];

export const TeamFilterForm: FC = () => {
  const [resetCount, setResetCount] = useState(0);
  const { t } = useTranslation();
  const inputs = useFilterInput();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = (body: unknown) => {
    // Reset all filter fields
    fields.forEach((field) => {
      searchParams.delete(field);
    });

    // Set filter fields
    fields.forEach((field) => {
      const value = get(body, field);
      if (value) {
        searchParams.set(field, value);
      }
    });

    // Set page to 1
    searchParams.set('page', '1');

    setSearchParams(searchParams);
  };

  const handleReset = () => {
    // Reset all filter fields
    fields.forEach((field) => {
      searchParams.delete(field);
    });

    // Set page to 1
    searchParams.set('page', '1');

    setSearchParams(searchParams);
    setResetCount((prev) => prev + 1);
  };

  const getInitialValues = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    for (const field of fields) {
      const value = searchParams.get(field);
      obj[field] = value ?? '';
    }

    return obj;
  };

  return (
    <Card key={resetCount}>
      <Formik onSubmit={handleSubmit} onReset={handleReset} initialValues={getInitialValues()}>
        <Form>
          <div className="space-y-4">
            <InputRenderer
              inputs={[
                inputs.nameSearchFilter,
                inputs.emailSearchFilter,
                inputs.isApproverSearchFilter,
                inputs.aclSearchFilter,
                inputs.groupSearchFilter,
              ]}
            />
            <div className="flex justify-between">
              <Button type="reset">{t('Filter:reset')}</Button>
              <Button type="submit">{t('Filter:submit')}</Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Card>
  );
};
