import { useMemo } from 'react';
import { useDocumentColumn } from '../../columns/document.column';
import { TableColumn } from '../../columns/table-column';

export const useDynamicDocumentColumns = (type: string) => {
  const documentColumn = useDocumentColumn();

  const defaultColumns = useMemo(
    () => [
      documentColumn.documentIdentifier,
      documentColumn.name,
      documentColumn.groupNames,
      documentColumn.createdAt,
      documentColumn.status,
    ],
    [documentColumn]
  );

  const qncColumns = useMemo(
    () => [
      documentColumn.documentIdentifier,
      documentColumn.name,
      documentColumn.category,
      documentColumn.groupNames,
      documentColumn.createdAt,
      documentColumn.status,
    ],
    [documentColumn]
  );

  const customColumns = useMemo(
    () => [
      documentColumn.documentIdentifier,
      documentColumn.type,
      documentColumn.name,
      documentColumn.groupNames,
      documentColumn.createdAt,
      documentColumn.status,
    ],
    [documentColumn]
  );

  const columns: TableColumn[] = useMemo(() => {
    if (type === 'custom') return customColumns;
    if (type === 'qnc') return qncColumns;
    return defaultColumns;
  }, [type, customColumns, qncColumns, defaultColumns]);

  return columns;
};
