import { userInput } from '../inputs/user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [userInput.company, userInput.email, userInput.name, userInput.password];

export const registerForm = createForm(form);

export type RegisterFormBody = {
  company: string;
  name: string;
  email: string;
  password: string;
};
