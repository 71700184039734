import { DocumentTypeField } from '../utils/DocumentType';

const routesConfig = {
  DASHBOARD: '/',

  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT: '/forgot',
  FORGOT_SUCCESS: '/forgot-success',
  RESET_PASSWORD: '/reset',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',
  CONFIRM: '/confirm',
  REGISTER: '/register',
  PRIVACY: '/privacy',
  ACCOUNT_DELETE: '/account-deletion',
  ACCEPT_INVITATION: '/accept-invitation',

  PUBLIC_PAGE: '/p/:id',
  PUBLIC_THANKS: '/p/:id/thanks',

  DOCUMENT_INDEX: '/documents',
  DOCUMENT_CREATE: '/documents/create',
  DOCUMENT_SHOW: '/documents/:id',
  DOCUMENT_MIRO: '/documents/:id/miro',
  DOCUMENT_MS: '/documents/:id/ms',
  DOCUMENT_EDIT: '/documents/:id/edit',
  DOCUMENT_EDITOR: '/documents/:id/editor',
  DOCUMENT_LINKS_CREATE: '/documents/:id/links/create',
  DOCUMENT_LINKS_EDIT: '/documents/:id/links/:linkId/edit',
  DOCUMENT_DRIVE_FILE_EDIT: '/documents/:id/drive-files/:driveFileId',
  DOCUMENT_REJECT: '/documents/:id/reject/:documentApproverId',
  DOCUMENT_SEND_REMINDER: '/documents/:id/send-reminder/:documentApproverId',
  DOCUMENT_ADD_APPROVER: '/documents/:id/add-approver',

  QNC_INDEX: '/qncs',
  QNC_CREATE: '/qncs/create',
  QNC_EDIT: '/qncs/:id/edit',
  QNC_EDITOR: '/qncs/:id/editor',
  QNC_SHOW: '/qncs/:id',
  QNC_MIRO: '/qncs/:id/miro',
  QNC_MS: '/qncs/:id/ms',
  QNC_LINKS_CREATE: '/qncs/:id/links/create',
  QNC_LINKS_EDIT: '/qncs/:id/links/:linkId/edit',
  QNC_DRIVE_FILE_EDIT: '/qncs/:id/drive-files/:driveFileId',
  QNC_REJECT: '/qncs/:id/reject/:documentApproverId',
  QNC_SEND_REMINDER: '/qncs/:id/send-reminder/:documentApproverId',
  QNC_ADD_APPROVER: '/qncs/:id/add-approver',

  RISK_INDEX: '/risks',
  RISK_SHOW: '/risks/:id',
  RISK_MIRO: '/risks/:id/miro',
  RISK_MS: '/risks/:id/ms',
  RISK_CREATE: '/risks/create',
  RISK_EDITOR: '/risks/:id/editor',
  RISK_EDIT: '/risks/:id/edit',
  RISK_LINKS_CREATE: '/risks/:id/links/create',
  RISK_LINKS_EDIT: '/risks/:id/links/:linkId/edit',
  RISK_DRIVE_FILE_EDIT: '/risks/:id/drive-files/:driveFileId',
  RISK_REJECT: '/risks/:id/reject/:documentApproverId',
  RISK_SEND_REMINDER: '/risks/:id/send-reminder/:documentApproverId',
  RISK_ADD_APPROVER: '/risks/:id/add-approver',

  ANONYMOUS_INDEX: '/anonymous',
  ANONYMOUS_SHOW: '/anonymous/:id',
  ANONYMOUS_MIRO: '/anonymous/:id/miro',
  ANONYMOUS_MS: '/anonymous/:id/ms',
  ANONYMOUS_CREATE: '/anonymous/create',
  ANONYMOUS_EDITOR: '/anonymous/:id/editor',
  ANONYMOUS_EDIT: '/anonymous/:id/edit',
  ANONYMOUS_LINKS_CREATE: '/anonymous/:id/links/create',
  ANONYMOUS_LINKS_EDIT: '/anonymous/:id/links/:linkId/edit',
  ANONYMOUS_DRIVE_FILE_EDIT: '/anonymous/:id/drive-files/:driveFileId',
  ANONYMOUS_REJECT: '/anonymous/:id/reject/:documentApproverId',
  ANONYMOUS_SEND_REMINDER: '/anonymous/:id/send-reminder/:documentApproverId',
  ANONYMOUS_ADD_APPROVER: '/anonymous/:id/add-approver',

  REPORT_INDEX: '/report',
  REPORT_QNC_COUNT: '/report/qnc-count',
  REPORT_QNC_COST: '/report/qnc-cost',
  REPORT_QNC_TYPE: '/report/qnc-type',
  REPORT_QNC_TAG: '/report/qnc-tag',

  USER_EDIT: '/settings/user',
  COMPANY_EDIT: '/settings/company',
  SETTINGS_ROOT: '/settings',

  DOCUMENT_MODEL_INDEX: '/settings/document-models',
  DOCUMENT_MODEL_EDIT: '/settings/document-models/:id/edit',
  DOCUMENT_MODEL_CREATE: '/settings/document-models/create',

  GROUP_INDEX: '/settings/groups',
  GROUP_CREATE: '/settings/groups/create',
  GROUP_EDIT: '/settings/groups/:id/edit',
  GROUP_SHOW: '/settings/groups/:id',

  TAG_INDEX: '/settings/tags',
  TAG_CREATE: '/settings/tags/create',
  TAG_EDIT: '/settings/tags/:id/edit',

  CUSTOM_DOCUMENT_INDEX: '/custom-documents',
  CUSTOM_DOCUMENT_CHOOSE: '/custom-documents/choose-model',
  CUSTOM_DOCUMENT_CREATE: '/custom-documents/:documentModelId/create',
  CUSTOM_DOCUMENT_EDIT: '/custom-documents/:id/edit',
  CUSTOM_DOCUMENT_SHOW: '/custom-documents/:id',
  CUSTOM_DOCUMENT_LINKS_CREATE: '/custom-documents/:id/links/create',
  CUSTOM_DOCUMENT_LINKS_EDIT: '/custom-documents/:id/links/:linkId/edit',
  CUSTOM_DOCUMENT_MIRO: '/custom-documents/:id/miro',
  CUSTOM_DOCUMENT_MS: '/custom-documents/:id/ms',
  CUSTOM_DOCUMENT_EDITOR: '/custom-documents/:id/editor',
  CUSTOM_DOCUMENT_DRIVE_FILE_EDIT: '/custom-documents/:id/drive-files/:driveFileId',
  CUSTOM_DOCUMENT_REJECT: '/custom-documents/:id/reject/:documentApproverId',
  CUSTOM_DOCUMENT_SEND_REMINDER: '/custom-documents/:id/send-reminder/:documentApproverId',
  CUSTOM_DOCUMENT_ADD_APPROVER: '/custom-documents/:id/add-approver',

  CUSTOM_REPORT_SHOW: '/report/custom/:id',
  CUSTOM_REPORT_CREATE: '/report/custom/create',

  TEAM_INDEX: '/settings/team',
  TEAM_EDIT: '/settings/team/:id/edit',
  TEAM_INVITE: '/settings/team/invite',

  EXPORT: '/settings/exports',
  DATA_VALIDATION: '/data-validation',

  ACL_INDEX: '/settings/acl',
  ACL_SHOW: '/settings/acl/:id',
  ACL_CREATE: '/settings/acl/create',
  ACL_EDIT: '/settings/acl/:id/edit',
};

type Key = keyof typeof routesConfig;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoute = (arg: Key, params?: any, skipValidation = false): string => {
  let url = routesConfig[arg];

  if (params) {
    const keys = Object.keys(params);

    const paramsLength = (url.match(/:/g) || []).length;

    if (!skipValidation) {
      if (keys.length !== paramsLength) {
        // eslint-disable-next-line no-console
        console.error('[getRoute] params and arguments mismatch', {
          arg,
          url,
          params,
          paramsLength,
        });
        throw new Error('[getRoute] params and arguments mismatch');
      }
    }

    keys.forEach((p) => {
      url = url.replace(`:${p}`, params[p]);
    });
  }

  if (url.includes(':')) throw new Error(`[getRoute] ${url} route missing params`);

  return url;
};

export const getDashboardRouteConfig = () => {
  return routesConfig;
};

type SendReminder =
  | 'DOCUMENT_SEND_REMINDER'
  | 'QNC_SEND_REMINDER'
  | 'RISK_SEND_REMINDER'
  | 'CUSTOM_DOCUMENT_SEND_REMINDER'
  | 'ANONYMOUS_SEND_REMINDER';

type RejectKey =
  | 'DOCUMENT_REJECT'
  | 'QNC_REJECT'
  | 'RISK_REJECT'
  | 'CUSTOM_DOCUMENT_REJECT'
  | 'ANONYMOUS_REJECT';

type IndexKey =
  | 'DOCUMENT_INDEX'
  | 'QNC_INDEX'
  | 'RISK_INDEX'
  | 'CUSTOM_DOCUMENT_INDEX'
  | 'ANONYMOUS_INDEX';

type ShowKey =
  | 'DOCUMENT_SHOW'
  | 'QNC_SHOW'
  | 'RISK_SHOW'
  | 'CUSTOM_DOCUMENT_SHOW'
  | 'ANONYMOUS_SHOW';

type CreateKey =
  | 'DOCUMENT_CREATE'
  | 'QNC_CREATE'
  | 'RISK_CREATE'
  | 'CUSTOM_DOCUMENT_CHOOSE'
  | 'ANONYMOUS_CREATE';

type DriveFileEditKey =
  | 'DOCUMENT_DRIVE_FILE_EDIT'
  | 'QNC_DRIVE_FILE_EDIT'
  | 'RISK_DRIVE_FILE_EDIT'
  | 'CUSTOM_DOCUMENT_DRIVE_FILE_EDIT'
  | 'ANONYMOUS_DRIVE_FILE_EDIT';

type EditKey =
  | 'DOCUMENT_EDIT'
  | 'QNC_EDIT'
  | 'RISK_EDIT'
  | 'CUSTOM_DOCUMENT_EDIT'
  | 'ANONYMOUS_EDIT';

type ApproverKey =
  | 'DOCUMENT_ADD_APPROVER'
  | 'QNC_ADD_APPROVER'
  | 'RISK_ADD_APPROVER'
  | 'CUSTOM_DOCUMENT_ADD_APPROVER'
  | 'ANONYMOUS_ADD_APPROVER';

type EditorKey =
  | 'DOCUMENT_EDITOR'
  | 'QNC_EDITOR'
  | 'RISK_EDITOR'
  | 'CUSTOM_DOCUMENT_EDITOR'
  | 'ANONYMOUS_EDITOR';

type MiroKey =
  | 'DOCUMENT_MIRO'
  | 'QNC_MIRO'
  | 'RISK_MIRO'
  | 'CUSTOM_DOCUMENT_MIRO'
  | 'ANONYMOUS_MIRO';

type MSKey = 'DOCUMENT_MS' | 'QNC_MS' | 'RISK_MS' | 'CUSTOM_DOCUMENT_MS' | 'ANONYMOUS_MS';

type CreateLinkKey =
  | 'DOCUMENT_LINKS_CREATE'
  | 'QNC_LINKS_CREATE'
  | 'RISK_LINKS_CREATE'
  | 'CUSTOM_DOCUMENT_LINKS_CREATE'
  | 'ANONYMOUS_LINKS_CREATE';

type EditLinkKey =
  | 'DOCUMENT_LINKS_EDIT'
  | 'QNC_LINKS_EDIT'
  | 'RISK_LINKS_EDIT'
  | 'CUSTOM_DOCUMENT_LINKS_EDIT'
  | 'ANONYMOUS_LINKS_EDIT';

export const getDocShowRoute = (type: DocumentTypeField): ShowKey => {
  if (type === 'document') return 'DOCUMENT_SHOW';
  if (type === 'qnc') return 'QNC_SHOW';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_SHOW';
  if (type === 'risk') return 'RISK_SHOW';
  if (type === 'anonymous') return 'ANONYMOUS_SHOW';

  throw new Error('Invalid document type');
};

export const getDocCreateMiroRoute = (type: DocumentTypeField): MiroKey => {
  if (type === 'document') return 'DOCUMENT_MIRO';
  if (type === 'qnc') return 'QNC_MIRO';
  if (type === 'risk') return 'RISK_MIRO';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_MIRO';
  if (type === 'anonymous') return 'ANONYMOUS_MIRO';

  throw new Error('Invalid document type');
};

export const getDocAddApproverRoute = (type: DocumentTypeField): ApproverKey => {
  if (type === 'document') return 'DOCUMENT_ADD_APPROVER';
  if (type === 'qnc') return 'QNC_ADD_APPROVER';
  if (type === 'risk') return 'RISK_ADD_APPROVER';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_ADD_APPROVER';
  if (type === 'anonymous') return 'ANONYMOUS_ADD_APPROVER';

  throw new Error('Invalid document type');
};

export const getDocCreateMSRoute = (type: DocumentTypeField): MSKey => {
  if (type === 'document') return 'DOCUMENT_MS';
  if (type === 'qnc') return 'QNC_MS';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_MS';
  if (type === 'risk') return 'RISK_MS';
  if (type === 'anonymous') return 'ANONYMOUS_MS';

  throw new Error('Invalid document type');
};

export const getDocEditorRoute = (type: DocumentTypeField): EditorKey => {
  if (type === 'document') return 'DOCUMENT_EDITOR';
  if (type === 'qnc') return 'QNC_EDITOR';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_EDITOR';
  if (type === 'risk') return 'RISK_EDITOR';
  if (type === 'anonymous') return 'ANONYMOUS_EDITOR';

  throw new Error('Invalid document type');
};

export const getDocCreateRoute = (type: DocumentTypeField): CreateKey => {
  if (type === 'document') return 'DOCUMENT_CREATE';
  if (type === 'qnc') return 'QNC_CREATE';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_CHOOSE';
  if (type === 'risk') return 'RISK_CREATE';
  if (type === 'anonymous') return 'ANONYMOUS_CREATE';

  throw new Error('Invalid document type');
};

export const getDocEditRoute = (type: DocumentTypeField): EditKey => {
  if (type === 'document') return 'DOCUMENT_EDIT';
  if (type === 'qnc') return 'QNC_EDIT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_EDIT';
  if (type === 'risk') return 'RISK_EDIT';
  if (type === 'anonymous') return 'ANONYMOUS_EDIT';

  throw new Error('Invalid document type');
};

export const getDocDriveFileEditRoute = (type: DocumentTypeField): DriveFileEditKey => {
  if (type === 'document') return 'DOCUMENT_DRIVE_FILE_EDIT';
  if (type === 'qnc') return 'QNC_DRIVE_FILE_EDIT';
  if (type === 'risk') return 'RISK_DRIVE_FILE_EDIT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_DRIVE_FILE_EDIT';
  if (type === 'anonymous') return 'ANONYMOUS_DRIVE_FILE_EDIT';

  throw new Error('Invalid document type');
};

export const getCreateLinkRoute = (type: DocumentTypeField): CreateLinkKey => {
  if (type === 'document') return 'DOCUMENT_LINKS_CREATE';
  if (type === 'qnc') return 'QNC_LINKS_CREATE';
  if (type === 'risk') return 'RISK_LINKS_CREATE';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_LINKS_CREATE';
  if (type === 'anonymous') return 'ANONYMOUS_LINKS_CREATE';

  throw new Error('Invalid document type');
};

export const getEditLinkRoute = (type: DocumentTypeField): EditLinkKey => {
  if (type === 'document') return 'DOCUMENT_LINKS_EDIT';
  if (type === 'qnc') return 'QNC_LINKS_EDIT';
  if (type === 'risk') return 'RISK_LINKS_EDIT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_LINKS_EDIT';
  if (type === 'anonymous') return 'ANONYMOUS_LINKS_EDIT';

  throw new Error('Invalid document type');
};

export const getDocIndexRoute = (type: DocumentTypeField): IndexKey => {
  if (type === 'document') return 'DOCUMENT_INDEX';
  if (type === 'qnc') return 'QNC_INDEX';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_INDEX';
  if (type === 'risk') return 'RISK_INDEX';
  if (type === 'anonymous') return 'ANONYMOUS_INDEX';

  throw new Error('Invalid document type');
};

export const getDocRejectRoute = (type: DocumentTypeField): RejectKey => {
  if (type === 'document') return 'DOCUMENT_REJECT';
  if (type === 'qnc') return 'QNC_REJECT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_REJECT';
  if (type === 'risk') return 'RISK_REJECT';
  if (type === 'anonymous') return 'ANONYMOUS_REJECT';

  throw new Error('Invalid document type');
};

export const getDocSendReminderRoute = (type: DocumentTypeField): SendReminder => {
  if (type === 'document') return 'DOCUMENT_SEND_REMINDER';
  if (type === 'qnc') return 'QNC_SEND_REMINDER';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_SEND_REMINDER';
  if (type === 'risk') return 'RISK_SEND_REMINDER';
  if (type === 'anonymous') return 'ANONYMOUS_SEND_REMINDER';

  throw new Error('Invalid document type');
};
