import { Form, Formik } from 'formik';
import { map } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { getDocShowRoute, getRoute } from '../../config/routes.config';
import {
  AddApproverForm,
  AddApproverFormBody,
  addApproverInitialValues,
  addApproverValidationSchema,
} from '../../forms/add-approver.form';
import {
  useAddApproverSceneQuery,
  useCreateDocumentApproverMutation,
} from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import { remapOptions } from '../../utils/document-remap-helpers';

type Props = {
  type: DocumentTypeField;
};

export const DocumentAddApproverScene: FC<Props> = ({ type }) => {
  const { t } = useTranslation('DocumentShowScene');
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) throw new Error('No document id');

  const url = getRoute(getDocShowRoute(type), { id });

  const [createApprover, { loading: isMutationLoading }] = useCreateDocumentApproverMutation({
    onCompleted: (d) => {
      if (d.createDocumentApprover) {
        navigate(url);
      }
    },
  });
  const { data, loading: isQueryLoading } = useAddApproverSceneQuery({
    variables: {
      documentId: id,
    },
  });

  const handleSubmit = ({ approverId }: AddApproverFormBody) => {
    if (!id) {
      throw new Error('No document id');
    }
    createApprover({
      variables: {
        id: id,
        userId: approverId,
      },
    });
  };

  if (isQueryLoading || !data) return <Loading />;

  return (
    <FormLayout>
      <Card>
        <CardTitle>{t(`addApprover`)}</CardTitle>
        <Formik
          validationSchema={addApproverValidationSchema}
          onSubmit={handleSubmit}
          initialValues={addApproverInitialValues}
        >
          <Form>
            <AddApproverForm users={map(data.availableDocumentApprovers, remapOptions)} />

            <div className="mb-4">
              <Button
                type="submit"
                className="float-right"
                loading={isMutationLoading || isQueryLoading}
              >
                {t('Common:save')}
              </Button>
              <ButtonLink to={url}>{t('Common:back')}</ButtonLink>
            </div>
          </Form>
        </Formik>
      </Card>
    </FormLayout>
  );
};
