import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';
import { inviteTeamForm, InviteTeamFormBody } from '../../forms/invite-team.form';
import { useInviteUserMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const TeamInviteScene = (): JSX.Element => {
  const { t } = useTranslation('TeamInviteScene');
  const navigate = useNavigate();

  const [inviteUser, { loading }] = useInviteUserMutation({
    onCompleted: (data) => {
      if (data.inviteUser) {
        toast.success(t('Common:updated'));
        navigate(getRoute('TEAM_INDEX'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: InviteTeamFormBody) => {
      inviteUser({ variables: { body } });
    },
    [inviteUser]
  );

  return (
    <AppFormScene
      type="create"
      title={t('inviteUser')}
      form={inviteTeamForm}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={getRoute('TEAM_INDEX')}
    />
  );
};
