import { changeLanguage } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';
import { authConfig } from '../config/auth.config';
import { setupQueryConfig } from '../config/query.config';
import { getRoute } from '../config/routes.config';
import ReFetchContext from '../contexes/ReFetchContext';
import UserContext, { UserContextData } from '../contexes/UserContext';
import { useWhoAmILazyQuery } from '../generated/graphql';
import { Loading } from './Loading';
import { AuthError } from './auth/AuthError';

type Props = {
  children: JSX.Element;
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom(event: string, data: any): void;
  }
}

export const RequireAuth = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [isReFetchRequested, setIsReFetchRequested] = useState(false);
  const { update, shutdown } = useIntercom();
  const [user, setUser] = useState<UserContextData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    return () => {
      shutdown();
    };
  }, [shutdown]);

  const onError = useCallback(() => {
    localStorage.removeItem(authConfig.tokenStorageKey);
    navigate(getRoute('LOGIN'));
  }, [navigate]);

  const [whoAmI, { data }] = useWhoAmILazyQuery({
    ...setupQueryConfig(),
    errorPolicy: 'none',
    onError,
  });

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem(authConfig.tokenStorageKey)) {
        onError();
      } else {
        await whoAmI();
        setIsLoading(false);
      }
    })();
  }, [whoAmI, setIsLoading, onError]);

  useEffect(() => {
    if (data?.whoAmI) {
      setUser({
        ...data.whoAmI,
        documentModelIdObject: data.documentModelIdObject,
      });
      changeLanguage(data.whoAmI.language);
      if ('Intercom' in window) {
        const ami = data.whoAmI;
        update({
          userId: ami.id,
          email: ami.email,
          name: ami.name,
          company: {
            companyId: ami.company.id,
            name: ami.company.name,
          },
        });
      }
    }
  }, [data, update]);

  useEffect(() => {
    if (isReFetchRequested) {
      setIsReFetchRequested(false);
      whoAmI();
    }
  }, [whoAmI, isReFetchRequested, setIsReFetchRequested]);

  const refresh = useCallback(() => {
    setIsReFetchRequested(true);
  }, [setIsReFetchRequested]);

  if (isLoading) return <Loading />;
  if (!user) return <AuthError loginRoute={getRoute('LOGIN')} errorReason="Unauthorized" />;

  return (
    <ReFetchContext.Provider value={refresh}>
      <UserContext.Provider value={[user, setUser]}>
        <RecoilRoot>{props.children}</RecoilRoot>
      </UserContext.Provider>
    </ReFetchContext.Provider>
  );
};
