import { rejectDocumentInput } from '../inputs/reject-document.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [rejectDocumentInput.reason];

export const rejectDocumentForm = createForm(form);

export type RejectDocumentFormBody = {
  reason: string;
};
