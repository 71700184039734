import { tagInput } from '../inputs/tag.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [tagInput.name];

export const tagForm = createForm(form);

export type TagFormBody = {
  name: string;
};
