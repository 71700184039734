import * as Yup from 'yup';
import { ArrayAddButton, ArrayInputLayout, ArrayRemoveButton } from '../components/ArrayInput';
import { FormInput } from '../releox-engine/form/FormInput';

const label: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'label',
    label: 'DocumentModel:label',
    type: 'text',
  },
};

const options: FormInput = {
  type: 'document-model-field-option-input',
  initValue: [],
  validation: Yup.array().of(Yup.string()),
  props: {
    name: 'options',
    label: 'DocumentModel:options',
  },
};

const type: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'type',
    label: 'DocumentModel:type',
    options: [
      { value: 'text', label: 'DocumentModel:text' },
      { value: 'select', label: 'DocumentModel:select' },
      { value: 'inline-select', label: 'DocumentModel:inlineSelect' },
      { value: 'number', label: 'DocumentModel:number' },
      { value: 'date', label: 'DocumentModel:date' },
    ],
  },
};

const fields: FormInput = {
  type: 'array',
  props: {
    name: 'documentModelFields',
    label: 'DocumentModel:documentModelFields',
    initialValues: {
      type: 'text',
      label: '',
      options: [],
      position: -1,
    },
    ArrayElement: ArrayInputLayout,
    AddElement: ArrayAddButton,
    RemoveElement: ArrayRemoveButton,
    elements: [type, label, options],
  },
};

const privilege: FormInput = {
  type: 'select',
  initValue: 'private',
  validation: Yup.string(),
  props: {
    name: 'privilege',
    label: 'DocumentModel:privilege',
    options: [
      { value: 'public', label: 'DocumentModel:public' },
      { value: 'private', label: 'DocumentModel:private' },
    ],
  },
};

const modelType: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    type: 'text',
    name: 'type',
    label: 'DocumentModel:modelType',
  },
};

export const documentModelInput = {
  fields,
  privilege,
  modelType,
};
