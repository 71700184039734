import { accountDeleteInput } from '../inputs/account-delete.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  accountDeleteInput.email,
  accountDeleteInput.name,
  accountDeleteInput.company,
];

export const accountDeleteForm = createForm(form);

export type AccountDeleteFormBody = {
  email: string;
  name: string;
  company: string;
};
