import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().email().required(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'email',
    autoComplete: 'email',
  },
};

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'User:name',
    type: 'text',
    autoComplete: 'name',
  },
};

const company: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'company',
    label: 'User:company',
    type: 'text',
  },
};

export const accountDeleteInput = {
  email,
  name,
  company,
};
