export default {
  fi: {
    Document: {
      name: 'Otsikko',
      status: 'Tila',
      userId: 'Käyttäjä',
      groups: 'Ryhmä',
      approverId: 'Hyväksyjä',
      createdAt: 'Luotu',
      category: 'Tyyppi',
      documentIdentifier: '#',
      groupNames: 'Ryhmät',
      description: 'Kuvaus',
      isApproveRequired: 'Vaatii hyväksynnän',
      tags: 'Tagit',
      createdBy: 'Luoja',
      approverName: 'Hyväksyjä',
      createdAtLte: 'Luotu ennen',
      createdAtGte: 'Luotu jälkeen',
      choose: 'Valitse',
      costs: 'Kustannukset',
      documentType: 'Pohja',
      type: 'Pohja',
      public: 'Julkinen',
    },
  },
  en: {
    Document: {
      name: 'Title',
      status: 'Status',
      userId: 'User',
      groups: 'Group',
      approverId: 'Approver',
      createdAt: 'Created',
      category: 'Type',
      documentIdentifier: '#',
      groupNames: 'Groups',
      description: 'Description',
      isApproveRequired: 'Requires approval',
      createAtLte: 'Created before',
      createAtGte: 'Created after',
      tags: 'Tags',
      createdBy: 'Creator',
      approverName: 'Approver',
      choose: 'Choose',
      costs: 'Costs',
      documentType: 'Document type',
      type: 'Document base',
      public: 'Public',
    },
  },
};
