import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReactSelectInput, ReactSelectInputType } from '../components/inputs/react-select-input';
import { SelectInput, SelectInputType } from '../components/inputs/select-input';
import { TextInput, TextInputType } from '../components/inputs/text-input';
import { useACLSelectValues } from '../hooks/select-values/use-acl-select-values';
import { useGroupSelectValues } from '../hooks/select-values/use-group-select-values';

export const useFilterInput = () => {
  const { t } = useTranslation('Filter');

  const nameSearchFilter: TextInputType = {
    type: 'text',
    element: TextInput,
    initValue: '',
    validation: Yup.string(),
    props: {
      name: 'name',
      label: t('name'),
      type: 'text',
    },
  };

  const emailSearchFilter: TextInputType = {
    type: 'text',
    element: TextInput,
    initValue: '',
    validation: Yup.string(),
    props: {
      name: 'email',
      label: t('email'),
      type: 'text',
    },
  };

  const isApproverSearchFilter: SelectInputType = {
    type: 'select',
    element: SelectInput,
    initValue: '',
    validation: Yup.string(),
    props: {
      name: 'isApprover',
      label: t('isApprover'),
      options: [
        {
          value: '',
          label: t('noSelection'),
        },
        {
          value: 'true',
          label: t('yes'),
        },
        {
          value: 'false',
          label: t('no'),
        },
      ],
    },
  };

  const aclSearchFilter: ReactSelectInputType = {
    type: 'react-select',
    element: ReactSelectInput,
    initValue: '',
    validation: Yup.string().optional(),
    props: {
      noOptionsMessage: t('noOptions'),
      label: t('acl'),
      name: 'resourceACLId',
      useOptions: useACLSelectValues,
    },
  };

  const groupSearchFilter: ReactSelectInputType = {
    type: 'react-select',
    element: ReactSelectInput,
    initValue: '',
    validation: Yup.string().optional(),
    props: {
      noOptionsMessage: t('noOptions'),
      label: t('groups'),
      name: 'groupId',
      useOptions: useGroupSelectValues,
    },
  };

  return {
    nameSearchFilter,
    emailSearchFilter,
    aclSearchFilter,
    isApproverSearchFilter,
    groupSearchFilter,
  };
};
