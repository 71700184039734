import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const message: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'message',
    label: 'SendReminderScene:message',
    type: 'text',
  },
};

export const sendReminderInput = {
  message,
};
