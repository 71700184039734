import { BackgroundClass } from '../BackgroundClass';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const AuthLayout = (props: Props) => {
  const { children, className } = props;

  return (
    <div className={`w-full h-screen justify-center py-64 px-6 lg:px-8 items-center ${className}`}>
      <BackgroundClass className="BlueBackground" />
      {children}
    </div>
  );
};
