import { userInput } from "../inputs/user.input";
import { createForm } from "../releox-engine/form/FormGenerator";
import { FormInput } from "../releox-engine/form/FormInput";

const form: FormInput[] = [userInput.email];

export const forgotForm = createForm(form);

export type ForgotFormBody = {
  email: string;
};
