import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDocShowRoute, getRoute } from '../../config/routes.config';
import { SendReminderFormBody, sendReminderForm } from '../../forms/send-reminder.form';
import { useSendReminderMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const SendReminderScene: FC<Props> = ({ type }) => {
  const { t } = useTranslation('SendReminderScene');
  const { documentApproverId, id } = useParams();
  if (!documentApproverId) throw new Error('Midding `documentApproverId`');

  const navigate = useNavigate();
  const SHOW = getDocShowRoute(type);

  const [sendReminder, { loading }] = useSendReminderMutation({
    onCompleted: (d) => {
      if (d.sendReminder) {
        toast.success(t('reminderSent'));
        navigate(getRoute(SHOW, { id }));
      }
    },
  });

  const handleSubmit = useCallback(
    ({ message }: SendReminderFormBody) => {
      sendReminder({
        variables: {
          documentApproverId,
          message,
        },
      });
    },
    [sendReminder, documentApproverId]
  );

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={sendReminderForm}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={getRoute(SHOW, { id: id })}
    />
  );
};
