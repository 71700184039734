import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDocShowRoute, getRoute } from '../../config/routes.config';
import { driveFileForm, DriveFileFormBody } from '../../forms/drive-file.form';
import { useDriveFileEditSceneQuery, useUpdateDriveFileMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const DocumentDriveFileEditScene = ({ type }: Props) => {
  const { t } = useTranslation('DocumentDriveFileEdit');
  const { id, driveFileId } = useParams();
  const navigate = useNavigate();
  if (!id) throw new Error('Missing ID');
  if (!driveFileId) throw new Error('Missing ID');

  const SHOW = getDocShowRoute(type);

  const query = useDriveFileEditSceneQuery({ variables: { id: driveFileId } });

  const [updateDriveFile, { loading }] = useUpdateDriveFileMutation({
    onCompleted: (data) => {
      if (data.updateDriveFile) {
        toast.success(t('Common:updated'));
        navigate(getRoute(SHOW, { id }));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: DriveFileFormBody) => {
      updateDriveFile({ variables: { id: driveFileId, body } });
    },
    [updateDriveFile, driveFileId]
  );
  return (
    <AppFormScene
      type="edit"
      query={query}
      queryDataPath="driveFile"
      title={t('title')}
      form={driveFileForm}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
      backLink={getRoute(SHOW, { id })}
    />
  );
};
