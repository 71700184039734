import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const rules: FormInput = {
  type: 'react-multi-select',
  initValue: [],
  validation: Yup.array().of(Yup.object().required()).min(1).required(),
  props: {
    name: 'rules',
    label: 'ACL:rules',
    placeholder: 'ACL:rulesPlaceholder',
  },
};

const model: FormInput = {
  type: 'react-select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'model',
    label: 'ACL:model',
    placeholder: 'ACL:modelPlaceholder',
  },
};

export const aclRuleInput = {
  rules,
  model,
};
