import { miroInput } from '../inputs/miro.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [miroInput.iframe];

export const miroForm = createForm(form);

export type MiroFormBody = {
  iframe: string;
};
