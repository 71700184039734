export const TeamInviteSceneTranslation = {
  fi: {
    TeamInviteScene: {
      inviteUser: 'Kutsu käyttäjä',
    },
  },
  en: {
    TeamInviteScene: {
      inviteUser: 'Invite user',
    },
  },
};
