import { Form, Formik } from 'formik';
import _, { get } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { getDocIndexRoute, getDocShowRoute, getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { viewPrivilegeAtom } from '../../contexes/view-privilege.atom';
import {
  DocumentCreateForm,
  DocumentCreateFormBody,
  DocumentCreateInitialValues,
  documentCreateInitialValues,
  documentCreateValidationSchema,
} from '../../forms/document-create.form';
import { useCreateDocumentMutation, useDocumentCreateSceneQuery } from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { DocumentTypeField } from '../../utils/DocumentType';
import {
  remapDocumentFormFieldToDocumentField,
  remapModelFieldToEmptyDocumentFormField,
  remapOptions,
} from '../../utils/document-remap-helpers';

type Props = {
  type: DocumentTypeField;
};

export const DocumentCreateScene = ({ type }: Props): JSX.Element => {
  const viewPrivilege = useRecoilValue(viewPrivilegeAtom);
  const [user] = useContext(UserContext);
  const { t } = useTranslation('DocumentCreateScene');
  const [initValues, setInitialValues] = useState<DocumentCreateInitialValues | null>(null);

  const haveReadPrivilege = get(viewPrivilege, `${type}.read`, false);

  const INDEX = getDocIndexRoute(type);
  const SHOW = getDocShowRoute(type);

  const onComplete = useOnComplete(haveReadPrivilege ? SHOW : 'DASHBOARD');

  const { data, loading: isQueryLoading } = useDocumentCreateSceneQuery({ variables: { type } });

  const [createDocument, { loading }] = useCreateDocumentMutation({
    onCompleted: onComplete,
  });

  useEffect(() => {
    const fields = _.map(
      data?.documentModelByType.documentModelFields,
      remapModelFieldToEmptyDocumentFormField
    );

    setInitialValues({
      ...documentCreateInitialValues,
      ...(type === 'qnc' ? { costs: 0 } : {}),
      fields,
    });
  }, [data?.documentModelByType.documentModelFields, type]);

  const handleSubmit = useCallback(
    (body: DocumentCreateFormBody) => {
      if (!data?.documentModelByType) {
        throw new Error('[DocumentCreateScene] documentModelByType is null');
      }

      createDocument({
        variables: {
          body: {
            ...body,
            tags: _.map(body.tags, 'value'),
            groups: _.map(body.groups, 'value'),
            fields: _.map(body.fields, remapDocumentFormFieldToDocumentField),
            type: type as string,
            documentModelId: data?.documentModelByType.id,
          },
        },
      });
    },
    [createDocument, type, data?.documentModelByType]
  );

  if (isQueryLoading || !data || !initValues) return <Loading />;

  return (
    <FormLayout key={JSON.stringify(initValues)}>
      <Card>
        <CardTitle>{t(`${type}Title`)}</CardTitle>
        <Formik
          validationSchema={documentCreateValidationSchema}
          onSubmit={handleSubmit}
          initialValues={{
            ...initValues,
            groups: user.defaultGroups.map(remapOptions),
            approverId: user.defaultApproverId ?? initValues.approverId,
          }}
        >
          <Form>
            <DocumentCreateForm
              groups={_.map(data.myGroups.list, remapOptions)}
              tags={_.map(data.tags.list, remapOptions)}
              users={data.allDocumentApprovers}
              type={type}
            />
            <Button type="submit" className="float-right" loading={loading}>
              {t('Common:save')}
            </Button>
            <ButtonLink to={haveReadPrivilege ? getRoute(INDEX) : getRoute('DASHBOARD')}>
              {t('Common:back')}
            </ButtonLink>
          </Form>
        </Formik>
      </Card>
    </FormLayout>
  );
};
