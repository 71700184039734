import { aclInput } from '../inputs/acl.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [aclInput.name];

export const aclForm = createForm(form);

export type ACLFormBody = {
  name: string;
};
