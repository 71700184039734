import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardTitle } from '../../components/CardTitle';
import { getDocEditRoute, getDocShowRoute, getRoute } from '../../config/routes.config';
import { MiroFormBody, miroForm } from '../../forms/miro.form';
import {
  useDeleteMiroLinkMutation,
  useMiroLinkQuery,
  useUpdateMiroLinkMutation,
} from '../../generated/graphql';
import { miroRegex } from '../../inputs/miro.input';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { BackButton } from '../../releox-engine/react-components/app-form-generator/BackButton';
import { DeleteButton } from '../../releox-engine/react-components/app-form-generator/DeleteButton';
import { SubmitButton } from '../../releox-engine/react-components/app-form-generator/SubmitButton';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const MiroScene = ({ type }: Props): JSX.Element => {
  const { t } = useTranslation('MiroScene');
  const { id } = useParams();
  if (!id) throw new Error('[MiroScene] Missing id');

  const SHOW = getDocShowRoute(type);
  const EDIT = getDocEditRoute(type);

  const [updateMiroLink, { loading }] = useUpdateMiroLinkMutation({
    onCompleted: useOnComplete(SHOW, 'saved'),
  });

  const { data } = useMiroLinkQuery({ variables: { id } });

  const [deleteMiroLink, { loading: isDeleteLoading }] = useDeleteMiroLinkMutation({
    variables: { id },
    onCompleted: useOnComplete(SHOW, 'deleted'),
  });

  const handleSubmit = useCallback(
    (body: MiroFormBody) => {
      const miroLink = _.last(body.iframe.match(miroRegex));

      if (!miroLink?.includes('https://miro.com')) return toast.error(t('invalid miro link url'));

      updateMiroLink({
        variables: {
          id,
          body: {
            miroLink,
          },
        },
      });
    },
    [updateMiroLink, t, id]
  );

  const Instructions = (
    <>
      <CardTitle>{t('updateMiroLink')}</CardTitle>
      <ul className="list-decimal text-sm text-gray-800 ml-5 space-y-2">
        <li>
          {t('instruction1')}
          <a
            href="https://miro.com/"
            target="_blank"
            rel="noreferrer"
            className="text-primary hover:text-secondary"
          >
            miro.com
          </a>
          {t('instruction2')}
        </li>
        <li>{t('instruction3')}</li>
        <li>{t('instruction4')}</li>
        <li>{t('instruction5')}</li>
      </ul>
    </>
  );

  const Footer = (
    <>
      <SubmitButton text={t('Common:save')} loading={loading || isDeleteLoading} />
      <BackButton to={getRoute(EDIT, { id })} />
      {data?.document.miroLink ? (
        <DeleteButton onClick={() => deleteMiroLink()} loading={loading || isDeleteLoading} />
      ) : null}
      <div className="clear-both" />
    </>
  );

  return (
    <AppFormScene
      type="create"
      title={t('updateMiroLink')}
      form={miroForm}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={getRoute(EDIT, { id })}
      HeaderElement={Instructions}
      FooterElement={Footer}
    />
  );
};
