import { documentModelInput } from '../inputs/document-model.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  documentModelInput.modelType,
  documentModelInput.privilege,
  documentModelInput.fields,
];

export const documentModelCreateForm = createForm(form);

type DocumentModelField = {
  type: string;
  label: string;
  position: number;
  options: string[];
};

export type DocumentModelCreateFormBody = {
  type: string;
  privilege: string;
  documentModelFields: DocumentModelField[];
};
