import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Loading } from '../../components/Loading';
import { EmptyTable } from '../../components/table/EmptyTable';
import { LoadingTable } from '../../components/table/LoadingTable';
import { Pagination } from '../../components/table/Pagination';
import { Table } from '../../components/table/Table';
import { TableBody } from '../../components/table/TableBody';
import { TableCol } from '../../components/table/TableCol';
import { TableHead } from '../../components/table/TableHead';
import { TableLayout } from '../../components/table/TableLayout';
import { TableRow } from '../../components/table/TableRow';
import { TableTH } from '../../components/table/TableTH';
import { Tag } from '../../components/tag/Tag';
import { TagContainer } from '../../components/tag/TagContainer';
import { getDocCreateRoute, getDocShowRoute, getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { DocumentFilterForm } from '../../forms/filters/document-filter/document-filter.form';
import { useDocumentIndexMetaQuery, useDocumentIndexSceneQuery } from '../../generated/graphql';
import { useDocumentSearchParams } from '../../hooks/index/use-document-search-params';
import { useDynamicDocumentColumns } from '../../hooks/index/use-dynamic-document-columns';
import { useOrderBy } from '../../hooks/index/use-order-by';
import { usePageNumber } from '../../hooks/index/use-page-number';
import { usePerPage } from '../../hooks/index/use-per-page';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
  showCreate?: boolean;
};

export const DocumentIndexScene = ({ type, showCreate = false }: Props): JSX.Element => {
  const { t } = useTranslation('DocumentIndexScene');
  const columns = useDynamicDocumentColumns(type);
  const [user] = useContext(UserContext);

  const page = usePageNumber();
  const perPage = usePerPage();

  const docSearchParams = useDocumentSearchParams(type);
  const { orderBy, orderDir } = useOrderBy();

  const { data: docData, loading } = useDocumentIndexSceneQuery({
    variables: {
      where: docSearchParams,
      orderBy: [{ [orderBy]: orderDir }],
      perPage,
      page,
    },
  });

  const { data } = useDocumentIndexMetaQuery({
    variables: {
      modelIds: user.myCustomDocuments.read,
    },
  });

  if (!data) return <Loading />;

  return (
    <TableLayout
      Filter={<DocumentFilterForm type={type} meta={data} />}
      Table={
        <Card>
          {showCreate && (
            <ButtonLink className="float-right" to={getRoute(getDocCreateRoute(type))}>
              {t('create')}
            </ButtonLink>
          )}
          <CardTitle>{t(type)}</CardTitle>
          <TagContainer>
            {data?.tags.list.map((tag) => <Tag tag={tag} key={tag.id} />)}
          </TagContainer>
          <Table>
            <TableHead>
              <tr>
                {columns.map((column) => (
                  <TableTH key={`${column.field}-${column.text}`} column={column} />
                ))}
              </tr>
            </TableHead>
            <TableBody>
              <LoadingTable columns={columns} loading={loading} />
              <EmptyTable columns={columns} data={docData?.documentsV2.list} loading={loading} />
              {docData?.documentsV2.list.map((doc) => (
                <TableRow
                  key={doc.id}
                  href={getRoute(getDocShowRoute(type), {
                    id: doc.id,
                  })}
                >
                  {columns.map((column) => (
                    <TableCol key={`${column.field}-${column.text}`} data={doc} column={column} />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination total={docData?.documentsV2.totalCount || 0} />
        </Card>
      }
    />
  );
};
