import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReactMultiSelectInput } from '../components/ReactMultiSelectInput';
import { ReactSelectInput } from '../components/ReactSelectInput';
import { DocumentModelOptionsQuery, useDocumentModelOptionsQuery } from '../generated/graphql';
import { aclRuleInput } from '../inputs/acl-rule.input';

export const aclRuleValidationSchema = Yup.object().shape({
  rules: aclRuleInput.rules.validation,
  model: aclRuleInput.model.validation,
});

export const aclRuleInitialValues = {
  rules: aclRuleInput.rules.initValue,
  model: aclRuleInput.model.initValue,
};

export type ACLRuleFormBody = {
  rules: { label: string; value: string }[];
  model: string;
};

export const ACLForm = () => {
  const { t, i18n } = useTranslation('ACLShowScene');
  const { data } = useDocumentModelOptionsQuery();

  return (
    <div className="space-y-3 mb-4">
      <ReactSelectInput
        {...aclRuleInput.model.props}
        options={get(data, 'documentModels.list', []).map(
          (doc: DocumentModelOptionsQuery['documentModels']['list'][0]) => ({
            value: doc.id,
            label: i18n.exists(`ACLShowScene:${doc.type}`) ? t(doc.type) : doc.type,
          })
        )}
      />
      <ReactMultiSelectInput
        {...aclRuleInput.rules.props}
        options={[
          {
            value: '*',
            label: t('all'),
          },
          {
            value: 'read',
            label: t('read'),
          },
          {
            value: 'create',
            label: t('create'),
          },
          {
            value: 'update',
            label: t('update'),
          },
          {
            value: 'delete',
            label: t('del'),
          },
        ]}
      />
    </div>
  );
};
