import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDocShowRoute, getRoute } from '../../config/routes.config';
import { RejectDocumentFormBody, rejectDocumentForm } from '../../forms/reject-document.form';
import { useRejectDocumentMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const RejectScene = ({ type }: Props) => {
  const { t } = useTranslation('RejectScene');
  const { id, documentApproverId } = useParams();
  if (!documentApproverId) throw new Error('Midding ID');
  const navigate = useNavigate();
  const SHOW = getDocShowRoute(type);
  const showUrl = getRoute(SHOW, { id: id });

  const [rejectDocument, { loading }] = useRejectDocumentMutation({
    onCompleted: (data) => {
      if (data.rejectDocument) {
        toast.success(t('Common:updated'));
        navigate(showUrl);
      }
    },
  });

  const handleSubmit = useCallback(
    (body: RejectDocumentFormBody) => {
      rejectDocument({
        variables: {
          documentApproverId: documentApproverId,
          body,
        },
      });
    },
    [rejectDocument, documentApproverId]
  );

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={rejectDocumentForm}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={showUrl}
    />
  );
};
