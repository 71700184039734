import { userInput } from "../inputs/user.input";
import { createForm } from "../releox-engine/form/FormGenerator";
import { FormInput } from "../releox-engine/form/FormInput";

const form: FormInput[] = [userInput.password];

export const resetPasswordForm = createForm(form);

export type ResetPasswordFormBody = {
  password: string;
};
