import { userInput } from '../inputs/user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  userInput.name,
  userInput.email,
  userInput.language,
  userInput.wantWeeklyNotification,
];

export const userForm = createForm(form);

export type UserFormBody = {
  name: string;
  email: string;
  language: string;
  wantWeeklyNotification: boolean;
};
