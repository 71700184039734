import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'CustomReport:name',
    type: 'text',
  },
};

const modelType: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'modelType',
    label: 'CustomReport:modelType',
    options: [],
  },
};

const fieldKey: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'fieldKey',
    label: 'CustomReport:fieldKey',
    options: [],
  },
};

export const customReportInput = {
  name,
  fieldKey,
  modelType,
};
