import * as Yup from 'yup';
import { ReactMultiSelectInput } from '../components/ReactMultiSelectInput';
import { ReactSelectInput, ReactSelectOption } from '../components/ReactSelectInput';
import { userInput } from '../inputs/user.input';
import { CheckboxInput } from '../releox-engine/react-components/form-inputs/Checkbox';
import { TextInput } from '../releox-engine/react-components/form-inputs/TextInput';

type Group = {
  label: string;
  value: string;
};

export type TeamFormBody = {
  name: string;
  isApprover: boolean;
  isCompanyAdmin: boolean;
  isAnonymousHandler: boolean;
  wantWeeklyNotification: boolean;
  groups: Group[];
  defaultGroups: Group[];
};

export const teamValidationSchema = Yup.object().shape({
  name: userInput.name.validation,
  isApprover: userInput.isApprover.validation,
  isCompanyAdmin: userInput.isCompanyAdmin.validation,
  wantWeeklyNotification: userInput.wantWeeklyNotification.validation,
  groups: userInput.groups.validation,
  defaultGroups: userInput.defaultGroups.validation,
});

export const teamInitialValues = {
  name: userInput.name.initValue,
  isApprover: userInput.isApprover.initValue,
  isCompanyAdmin: userInput.isCompanyAdmin.initValue,
  groups: userInput.groups.initValue,
  defaultGroups: userInput.defaultGroups.initValue,
};

type Props = {
  groups: ReactSelectOption[];
  acls: ReactSelectOption[];
  approvers: ReactSelectOption[];
};

export const TeamForm = ({ groups, acls, approvers }: Props) => {
  return (
    <div className="space-y-3 mb-4">
      <TextInput {...userInput.name.props} />
      <ReactMultiSelectInput {...userInput.groups.props} options={groups} />
      <ReactMultiSelectInput {...userInput.defaultGroups.props} options={groups} />
      <ReactSelectInput {...userInput.resourceACLId.props} options={acls} />
      <ReactSelectInput {...userInput.defaultApproverId.props} options={approvers} />
      <CheckboxInput {...userInput.isApprover.props} />
      <CheckboxInput {...userInput.isCompanyAdmin.props} />
      <CheckboxInput {...userInput.isAnonymousHandler.props} />
      <CheckboxInput {...userInput.wantWeeklyNotification.props} />
    </div>
  );
};
