export const HomeSceneTranslation = {
  fi: {
    HomeScene: {
      qncs: 'Poikkeamat',
      showAll: 'Näytä kaikki ',
      documents: 'Laatudokumentit',
      risks: 'Riskit ja mahdollisuudet',
      showAllBtn: 'Näytä kaikki',
      new: 'Uusi',
      approved: 'Hyväksytyt',
      sent: 'Lähetetty',
      unapproved: 'Hyväksymättä',
      approveNotRequired: 'Ei vaadi hyväksyntää',
      addNewQnc: 'Lisää uusi poikkeama tai havainto',
      addNewRisk: 'Lisää uusi riski',
      addNewDocument: 'Lisää uusi laatudokumentti',
      addNewCustomDocument: 'Lisää uusi lomake',
      customDocuments: 'Lomakkeet',
    },
  },
  en: {
    HomeScene: {
      qncs: 'QNCs',
      showAll: 'Show all ',
      documents: 'Documents',
      risks: 'Risks',
      showAllBtn: 'Show all',
      new: 'New',
      approved: 'Approved',
      sent: 'Sent',
      unapproved: 'Unapproved',
      approveNotRequired: 'Approve not required',
      addNewQnc: 'Create a new qnc',
      addNewDocument: 'Create a new document',
      addNewRisk: 'Create a new risk',
      customDocuments: 'Custom documents',
      addNewCustomDocument: 'Create a new custom document',
    },
  },
};
