import { FieldArray } from 'formik';
import * as Yup from 'yup';
import { InlineSelectInput } from '../components/InlineSelectInput';
import { ReactMultiSelectInput } from '../components/ReactMultiSelectInput';
import { ReactSelectInput } from '../components/ReactSelectInput';
import { documentInput } from '../inputs/document.input';
import { CheckboxInput } from '../releox-engine/react-components/form-inputs/Checkbox';
import { SelectInput } from '../releox-engine/react-components/form-inputs/SelectInput';
import { TextInput } from '../releox-engine/react-components/form-inputs/TextInput';

type Option = {
  label: string;
  value: string;
};

export type DocumentCreateFormFields = {
  value: string;
  id?: string;
  key: string;
  label: string;
  options: string[];
  type: string;
};

export type DocumentCreateFormBody = {
  name: string;
  groups: Option[];
  isApproveRequired: boolean;
  approverId: string;
  tags: Option[];
  category: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: DocumentCreateFormFields[];
};

export const documentCreateValidationSchema = Yup.object().shape({
  name: documentInput.name.validation,
  groups: documentInput.groups.validation,
  isApproveRequired: documentInput.isApproveRequired.validation,
  approverId: documentInput.approverId.validation,
  tags: documentInput.tags.validation,
  description: documentInput.description.validation,
  category: documentInput.qncCategories.validation,
  fields: Yup.array()
    .of(
      Yup.object({
        value: Yup.string().optional(),
        id: Yup.string().optional(),
        key: Yup.string().required(),
        label: Yup.string().required(),
        options: Yup.array().of(Yup.string()).required(),
        type: Yup.string().required(),
      }).required()
    )
    .required(),
});

export const documentCreateInitialValues = {
  name: documentInput.name.initValue,
  isApproveRequired: documentInput.isApproveRequired.initValue,
  approverId: documentInput.approverId.initValue,
  tags: documentInput.tags.initValue,
  groups: documentInput.groups.initValue,
  description: documentInput.description.initValue,
  category: documentInput.qncCategories.initValue,
  fields: [] as DocumentCreateFormFields[],
};

type Init = typeof documentCreateInitialValues;

export type DocumentCreateInitialValues = Init | (Init & { costs: number });

type Props = {
  groups?: Option[];
  users?: Option[];
  tags?: Option[];
  type: string;
};

export const DocumentCreateForm = ({ groups, users, tags, type }: Props) => {
  return (
    <div className="space-y-3 mb-4">
      <TextInput {...documentInput.name.props} />
      {users ? <CheckboxInput {...documentInput.isApproveRequired.props} /> : null}
      {users ? <ReactSelectInput {...documentInput.approverId.props} options={users} /> : null}
      {type === 'qnc' ? <TextInput {...documentInput.costs.props} /> : null}
      {groups ? <ReactMultiSelectInput {...documentInput.groups.props} options={groups} /> : null}
      {tags ? <ReactMultiSelectInput {...documentInput.tags.props} options={tags} /> : null}
      <TextInput {...documentInput.description.props} />
      {type === 'qnc' ? <SelectInput {...documentInput.qncCategories.props} /> : null}
      <FieldArray
        name="fields"
        render={(arrayHelpers) => {
          const fields = arrayHelpers.form.values.fields as DocumentCreateFormFields[];
          return fields.map((field, i) => {
            const name = `fields.${i}.value`;
            if (field.type === 'select') {
              return (
                <SelectInput
                  key={field.key}
                  name={name}
                  options={field.options.map((o) => ({ value: o, label: o }))}
                  label={field.label}
                  placeholder="Document:choose"
                />
              );
            }
            if (field.type === 'inline-select') {
              return (
                <InlineSelectInput
                  key={field.key}
                  name={name}
                  options={field.options.map((o) => ({ value: o, label: o }))}
                  label={field.label}
                />
              );
            }
            if (field.type === 'date') {
              return <TextInput type="date" key={field.key} name={name} label={field.label} />;
            }
            if (field.type === 'text') {
              return <TextInput type="text" key={field.key} name={name} label={field.label} />;
            }
            if (field.type === 'number') {
              return <TextInput type="number" key={field.key} name={name} label={field.label} />;
            }
            return <span key={field.key}>{field.label}</span>;
          });
        }}
      />
    </div>
  );
};
