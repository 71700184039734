import { Route, Routes } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import './App.css';
import { DashboardRouter } from './DashboardRouter';
import { RequireAuth } from './components/RequireAuth';
import { RequireGuest } from './components/RequireGuest';
import { getDashboardRouteConfig } from './config/routes.config';
import { usePageTracking } from './hooks/use-page-tracking';
import { AccountDeleteScene } from './scenes/AccountDelete';
import { AcceptInvitationScene } from './scenes/auth/accept-invitation/AcceptInvitationScene';
import { ConfirmScene } from './scenes/auth/confirm/ConfirmScene';
import { ForgotScene } from './scenes/auth/forgot/ForgotScene';
import { ForgotSuccessScene } from './scenes/auth/forgot/ForgotSuccessScene';
import { LoginScene } from './scenes/auth/login/LoginScene';
import { LogoutScene } from './scenes/auth/logout/Logout';
import { RegisterScene } from './scenes/auth/register/RegisterScene';
import { ResetPasswordScene } from './scenes/auth/reset/ResetPasswordScene';
import { ResetPasswordSuccessScene } from './scenes/auth/reset/ResetPasswordSuccessScene';
import { PrivacyScene } from './scenes/privacy';
import { PublicDocumentCreate } from './scenes/public-document-create/PublicDocumentCreate';
import { PublicThanksScene } from './scenes/public-document-create/PublicThanksScene';

const routes = getDashboardRouteConfig();

export const App = () => {
  usePageTracking();
  return (
    <Routes>
      <Route path={routes.PRIVACY} element={<PrivacyScene />} />
      <Route path={routes.ACCOUNT_DELETE} element={<AccountDeleteScene />} />
      <Route path={routes.LOGOUT} element={<LogoutScene />} />
      <Route
        path={routes.ACCEPT_INVITATION}
        element={
          <RequireGuest>
            <AcceptInvitationScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <RequireGuest>
            <LoginScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.FORGOT}
        element={
          <RequireGuest>
            <ForgotScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.FORGOT_SUCCESS}
        element={
          <RequireGuest>
            <ForgotSuccessScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.RESET_PASSWORD}
        element={
          <RequireGuest>
            <ResetPasswordScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.RESET_PASSWORD_SUCCESS}
        element={
          <RequireGuest>
            <ResetPasswordSuccessScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.CONFIRM}
        element={
          <RequireGuest>
            <ConfirmScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.REGISTER}
        element={
          <RequireGuest>
            <RegisterScene />
          </RequireGuest>
        }
      />
      <Route path={routes.PUBLIC_PAGE} element={<PublicDocumentCreate />} />
      <Route path={routes.PUBLIC_THANKS} element={<PublicThanksScene />} />
      <Route
        path="*"
        element={
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM || ''} autoBoot>
            <RequireAuth>
              <DashboardRouter />
            </RequireAuth>
          </IntercomProvider>
        }
      />
    </Routes>
  );
};
