import { useEffect, useState } from 'react';
import { initialize, pageview } from 'react-ga';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_UA) {
      initialize(process.env.REACT_APP_ANALYTICS_UA);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};
