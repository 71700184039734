import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';
import { tagForm, TagFormBody } from '../../forms/tag.form';
import {
  useDeleteTagMutation,
  useTagEditSceneQuery,
  useUpdateTagMutation,
} from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const TagEditScene = (): JSX.Element => {
  const { t } = useTranslation('TagEditScene');
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) throw new Error('Missing ID');
  const query = useTagEditSceneQuery({ variables: { id } });

  const [updateTag, { loading }] = useUpdateTagMutation({
    onCompleted: (data) => {
      if (data.updateTag) {
        toast.success(t('Common:updated'));
        navigate(getRoute('TAG_INDEX'));
      }
    },
  });

  const [deleteTag] = useDeleteTagMutation({
    onCompleted: (data) => {
      if (data.deleteTag) {
        toast.success(t('Common:deleted'));
        navigate(getRoute('TAG_INDEX'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: TagFormBody) => {
      updateTag({ variables: { id, body } });
    },
    [updateTag, id]
  );

  const handleDelete = useCallback(() => {
    deleteTag({ variables: { id } });
  }, [deleteTag, id]);

  return (
    <AppFormScene
      type="edit"
      query={query}
      queryDataPath="tag"
      title={t('title')}
      form={tagForm}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
      backLink={getRoute('TAG_INDEX')}
      onDelete={handleDelete}
    />
  );
};
