import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const reason: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'reason',
    label: 'Reject:reason',
    type: 'text',
  },
};

export const rejectDocumentInput = {
  reason,
};
