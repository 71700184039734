import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const iframe: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .matches(
      /^<iframe[a-zA-Z0-9=" ]*src="https:\/\/[a-zA-Z-]*.sharepoint.com\/[a-zA-Z0-9/_.?&=;{}-]*"/
    )
    .required(),
  props: {
    name: 'iframe',
    label: 'MSLink:iframe',
    type: 'text',
  },
};

export const msLinkInput = {
  iframe,
};
