import {
  BookmarkAltIcon,
  ChartBarIcon,
  CogIcon,
  DocumentTextIcon,
  HomeIcon,
  ShieldExclamationIcon,
  SpeakerphoneIcon,
} from '@heroicons/react/outline';
import { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import UserContext from '../contexes/UserContext';
import { viewPrivilegeAtom } from '../contexes/view-privilege.atom';
import { getRoute } from './routes.config';

export type SideBarDropdown = {
  name: string;
  href: string;
  icon: typeof HomeIcon;
  links: SideBarLink[];
  show: boolean;
};

export type SideBarLink = {
  name: string;
  href: string;
  icon?: typeof HomeIcon;
  current: boolean;
  preventActive?: boolean;
  exact?: boolean;
  show: boolean;
};

export type SideBarNavItem = SideBarLink | SideBarDropdown;

export const useSideBarConfig = (): SideBarNavItem[] => {
  const [user] = useContext(UserContext);
  const viewPrivilege = useRecoilValue(viewPrivilegeAtom);
  const [list, setList] = useState<SideBarNavItem[]>([]);

  useEffect(() => {
    const sidebarConfig: SideBarNavItem[] = [
      {
        name: 'home',
        href: getRoute('DASHBOARD'),
        icon: HomeIcon,
        current: false,
        exact: true,
        show: true,
      },
      {
        name: 'reports',
        href: getRoute('REPORT_INDEX'),
        icon: ChartBarIcon,
        current: false,
        show: user.isCompanyAdmin || user.isCompanyOwner,
      },
      {
        name: 'documents',
        href: getRoute('DOCUMENT_INDEX'),
        icon: DocumentTextIcon,
        current: false,
        show: viewPrivilege.document.read,
      },
      {
        name: 'qncs',
        href: getRoute('QNC_INDEX'),
        icon: BookmarkAltIcon,
        current: false,
        show: viewPrivilege.qnc.read,
      },
      {
        name: 'risks',
        href: getRoute('RISK_INDEX'),
        icon: ShieldExclamationIcon,
        current: false,
        show: viewPrivilege.risk.read,
      },
      {
        name: 'anonymous',
        href: getRoute('ANONYMOUS_INDEX'),
        icon: SpeakerphoneIcon,
        current: false,
        show: user.isAnonymousHandler,
      },
      {
        name: 'customDocuments',
        href: getRoute('CUSTOM_DOCUMENT_INDEX'),
        icon: DocumentTextIcon,
        current: false,
        show: user.myCustomDocuments.read.length > 0,
      },
      {
        name: 'settings',
        href: getRoute('SETTINGS_ROOT'),
        icon: CogIcon,
        current: false,
        show: true,
        links: [
          {
            name: 'userEdit',
            href: getRoute('USER_EDIT'),
            current: false,
            show: true,
          },
          {
            name: 'teamEdit',
            href: getRoute('TEAM_INDEX'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'companyEdit',
            href: getRoute('COMPANY_EDIT'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'tagIndex',
            href: getRoute('TAG_INDEX'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'groupIndex',
            href: getRoute('GROUP_INDEX'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'documentModelIndex',
            href: getRoute('DOCUMENT_MODEL_INDEX'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'acl',
            href: getRoute('ACL_INDEX'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'exports',
            href: getRoute('EXPORT'),
            current: false,
            show: user.isCompanyAdmin || user.isCompanyOwner,
          },
          {
            name: 'logOut',
            href: getRoute('LOGOUT'),
            current: false,
            show: true,
          },
        ],
      },
    ];

    setList(
      sidebarConfig.filter((item) => {
        if (item.show === false) return false;
        if ('links' in item && item.links.length > 0) {
          item.links = item.links.filter((link) => link.show);
          return item.links.length > 0;
        }
        return true;
      })
    );
  }, [user, setList, viewPrivilege.document.read, viewPrivilege.qnc.read, viewPrivilege.risk.read]);

  return list;
};
