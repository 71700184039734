import * as Yup from 'yup';
import { ReactSelectInput } from '../components/ReactSelectInput';
import { documentInput } from '../inputs/document.input';

type Option = {
  label: string;
  value: string;
};

export type AddApproverFormBody = {
  approverId: string;
};

export const addApproverValidationSchema = Yup.object().shape({
  approverId: documentInput.approverId.validation,
});

export const addApproverInitialValues = {
  approverId: documentInput.approverId.initValue,
};

type Init = typeof addApproverInitialValues;

export type AddApproverInitialValues = Init;

type Props = {
  users?: Option[];
};

export const AddApproverForm = ({ users }: Props) => {
  return (
    <div className="space-y-3 mb-4">
      {users ? <ReactSelectInput {...documentInput.approverId.props} options={users} /> : null}
    </div>
  );
};
