import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { ReportQncCountChart } from '../../components/report/ReportQncCountChart';
import { ReportForm, ReportFormBody, reportInitialValues } from '../../forms/report.form';
import { useReportQncCountSceneLazyQuery } from '../../generated/graphql';

export const ReportQncCountScene = () => {
  const { t } = useTranslation('ReportQncCountScene');
  const [fetch, { data, loading }] = useReportQncCountSceneLazyQuery();

  useEffect(() => {
    fetch({ variables: reportInitialValues });
  }, [fetch]);

  const handleSubmit = useCallback(
    (body: ReportFormBody) => {
      fetch({ variables: body });
    },
    [fetch]
  );

  return (
    <Card>
      <CardTitle>{t('title')}</CardTitle>
      <ReportForm onSubmit={handleSubmit} />
      <ReportQncCountChart data={data?.qncCountReport} loading={loading} />
    </Card>
  );
};
