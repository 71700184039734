import { groupInput } from '../inputs/group.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [groupInput.name];

export const groupForm = createForm(form);

export type GroupFormBody = {
  name: string;
};
