import { sendReminderInput } from '../inputs/send-reminder.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [sendReminderInput.message];

export const sendReminderForm = createForm(form);

export type SendReminderFormBody = {
  message: string;
};
