import { createContext } from 'react';
import { WhoAmIQuery } from '../generated/graphql';

export type UserContextData = WhoAmIQuery['whoAmI'] & {
  documentModelIdObject: WhoAmIQuery['documentModelIdObject'];
};

const init: UserContextData = {
  id: '',
  name: '',
  language: '',
  email: '',
  isCompanyAdmin: false,
  isAnonymousHandler: false,
  isCompanyOwner: false,
  company: {
    id: '',
    name: '',
  },
  defaultGroups: [],
  documentModelIdObject: {
    risk: '',
    qnc: '',
    document: '',
    anonymous: null,
  },
  myCustomDocuments: {
    read: [],
    create: [],
    edit: [],
    delete: [],
  },
};

export default createContext<[UserContextData, (user: UserContextData) => void]>([
  init,
  () => ({}),
]);
