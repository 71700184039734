import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';
import { groupForm, GroupFormBody } from '../../forms/group.form';
import { useCreateGroupMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const GroupCreateScene = (): JSX.Element => {
  const { t } = useTranslation('GroupCreateScene');
  const navigate = useNavigate();

  const [createGroup, { loading }] = useCreateGroupMutation({
    onCompleted: (data) => {
      if (data.createGroup) {
        toast.success(t('Common:updated'));
        navigate(getRoute('GROUP_INDEX'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: GroupFormBody) => {
      createGroup({ variables: { body } });
    },
    [createGroup]
  );

  return (
    <AppFormScene
      type="create"
      title={t('createGroup')}
      form={groupForm}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={getRoute('GROUP_INDEX')}
    />
  );
};
