import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { documentModelCreateForm } from '../../forms/document-model-create.form';
import { useCreateDocumentModelMutation } from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { useSubmitHandler } from '../../releox-engine/use-submit-handler/use-submit-handler';

export const DocumentModelCreateScene = (): JSX.Element => {
  const { t } = useTranslation('DocumentModelCreateScene');
  const onComplete = useOnComplete('DOCUMENT_MODEL_INDEX', 'saved');
  const [createDocumentModel, { loading }] = useCreateDocumentModelMutation({
    onCompleted: onComplete,
  });

  const handleSubmit = useSubmitHandler(createDocumentModel);

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={documentModelCreateForm}
      isSubmitLoading={loading}
      backLink={getRoute('DOCUMENT_MODEL_INDEX')}
      onSubmit={handleSubmit}
    />
  );
};
