import { documentModelInput } from '../inputs/document-model.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [documentModelInput.privilege, documentModelInput.fields];

export const documentModelEditForm = createForm(form);

type DocumentModelField = {
  type: string;
  label: string;
  position: number;
  options: string[];
};

export type DocumentModelEditFormBody = {
  privilege: string;
  documentModelFields: DocumentModelField[];
};
