import { documentLinkInput } from '../inputs/document-link.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [documentLinkInput.name, documentLinkInput.url];

export const documentLinkForm = createForm(form);

export type DocumentLinkFormBody = {
  name: string;
  url: string;
};
