import { userInput } from '../inputs/user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [userInput.email, userInput.name];

export const inviteTeamForm = createForm(form);

export type InviteTeamFormBody = {
  name: string;
  email: string;
};
