export const DocumentIndexSceneTranslation = {
  fi: {
    DocumentIndexScene: {
      document: 'Laatudokumentit',
      qnc: 'Poikkeamat',
      risk: 'Riskit ja mahdollisuudet',
      create: 'Lisää uusi',
      anonymous: 'Anonyymi ilmoituskanava',
      custom: 'Lomakkeet',
    },
  },
  en: {
    DocumentIndexScene: {
      document: 'Documents',
      qnc: 'QNCs',
      risk: 'Risks',
      create: 'Add new',
      anonymous: 'Anonymous channel',
      custom: 'Custom documents',
    },
  },
};
