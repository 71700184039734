import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'DriveFile:name',
    type: 'text',
  },
};

export const driveFileInput = {
  name,
};
