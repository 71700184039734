import { driveFileInput } from '../inputs/drive-file.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [driveFileInput.name];

export const driveFileForm = createForm(form);

export type DriveFileFormBody = {
  name: string;
};
