export const ReportIndexSceneTranslation = {
  fi: {
    ReportIndexScene: {
      QncReport: 'Poikkeamaraportti',
      QncTypeReport: 'Poikkeamatyyppiraportti',
      QncCostReport: 'Poikkeamakustannusraportti',
      QncTagReport: 'Poikkeamatagiraportti',
      last12months: 'Viimeiseltä 12 kuukaudelta',
      createReport: 'Luo raportti',
    },
  },
  en: {
    ReportIndexScene: {
      QncReport: 'QNC Report',
      QncTypeReport: 'QNC Type Report',
      QncCostReport: 'QNC Costs Report',
      QncTagReport: 'QNC Tag Report',
      last12months: 'For the last 12 months',
      createReport: 'Create report',
    },
  },
};
